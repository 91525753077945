.notLandingPage {
    height: 93vh;
    overflow-x: hidden;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
}

.landingPageBG {
    /*background-image: url('../img/MAPA.jpg');
    background-position: center;*/

    /*background-image: url('../img/MAPA2.jpg');
    background-position: center;*/

    background-image: url('../img/NorwayMountains.jpg');
    background-position: bottom;

    background-repeat: no-repeat;
    background-size: cover;
    background-color: transparent;
}

.landingPage {
    background-color: #ff709cFF;
    transition: height 0.6s ease-out, background-color 0.3s ease-out;

    display: flex;
    /*height: 100%;*/
    flex-direction: column;
    justify-content: center;
    padding: 20px 0;
}

.landingPageHeight {
    height: 100vh;
}

.notLandingPageHeight {
    height: 7vh;
    overflow: hidden;
}

@media only screen and (max-width: 600px) {
    .notLandingPageHeight {
        height: 25vh;
        padding: 0;
    }
}

.logoAndLoginContainer {
    position: absolute;
    top: 25px;
    pointer-events: none; /* To stop the div from disabling clicks on div underneath */
}

@media only screen and (max-width: 600px) {
    .logoAndLoginContainer {
        top: 0;
    }
}

.loginContainer {
    flex: 0 1 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    height: 100%;
}

.landingPageContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;

    flex: 1 1 auto;
    /* The above is shorthand for:
    flex-grow: 1,
    flex-shrink: 1,
    flex-basis: auto
    */
}

@media only screen and (max-width: 600px) {
    .landingPageContainer {
        margin-top: 80px;
    }
}

.center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.landingPageJumbo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    width: 100%;
    padding-bottom: 15px;
}

@media only screen and (max-width: 600px) {
    .landingPageJumbo {
        padding: 15px 0;
    }
}

.searchBarContainer {
    width: 100%;
    height: 30px;
    margin-bottom: 5px;
    border-bottom: 1px solid white;
}

.landingPageLinksContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 100%;
}

/* ######################  RECIPE PAGE  ############ */

.recipePage {
    min-height: 90%;
    width: 100%;
    padding-bottom: 80px;

    overflow: hidden;
}

.recipePageHeader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    background-color: darkgray;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    width: 100%;
    height: 45vh;
}

.imageAndTitleContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    min-height: 25vh;
}

.recipePageJumboContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    height: inherit;
    background-color: #0b86bf00; /* ADJUST OPACITY HERE */
}

.recipePageJumbo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    height: 100%;
}

.recipePageContainer {

    width: 100%;
    /*min-height: 58vh;*/
    min-height: 90%;
    max-height: 100%; /* - if I want the recipe to minimum fit into vh on scroll down */
    background-color: #f3f3f3;
    margin-bottom: 20px;
    padding: 12px;
}

.instructionsContainer {
    background-color: #f3f3f3;
}

/* ######################  ALL RECIPES PAGE  ############ */

.allRecipesPage {
    min-height: 90%;
    width: 100%;
    padding-bottom: 80px;

    overflow: hidden;
}

.allRecipesPageContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #f3f3f3;

    padding: 20px;
}

/* ######################  LOGIN PAGE ##################### */

.loginPage {
    min-height: 90%;
    width: 100%;
    padding-bottom: 80px;

    overflow: hidden;
}

.loginPageContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #f3f3f3;

    padding: 20px;
}

/* ######################  EDIT RECIPE PAGE ##################### */

.adminPageContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    width: 100%;
}

.editRecipePage {
    min-height: 90%;
    padding-bottom: 80px;

    overflow-y: scroll;
    overflow-x: hidden;
}

.editRecipePageContainer {
    /*min-height: 58vh;*/
    min-height: 90%;
    max-height: 100%; /* - if I want the recipe to minimum fit into vh on scroll down */
    background-color: #f3f3f3;
    margin-bottom: 20px;
    padding: 12px;
    padding-top: 40px;
}

.ingredientsInputContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    width: 100%;
}

.richEditor {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 100%;
}

.inputFieldsContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.formLabelContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
}

.editPageInput {
    width: 100%;
}

.editPageInputNumber {
    width: 40px;
}

.radioButtonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.radioButtonContainer {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    margin-right: 20px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.radioButtonContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
    position: absolute;
    top: 4px;
    left: 4px;
    height: 16px;
    width: 16px;
    background-color: #ccc;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radioButtonContainer:hover input ~ .checkmark {
    background-color: #aaa;
}

/* When the radio button is checked, add a blue background */
.radioButtonContainer input:checked ~ .checkmark {
    background-color: #ff709c;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.radioButtonContainer input:checked ~ .checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.radioButtonContainer .checkmark:after {
    top: 4px;
    left: 4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}

.selectMealForm {

}

.imagePreviewContainer {
    width: 100%;
    height: 45vh;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    margin-bottom: 20px;
}

.dietaryCheckboxesContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.dietaryCheckboxContainer {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    margin-right: 20px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checbox button */
.dietaryCheckboxContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom checbox button */
.checkbox {
    position: absolute;
    top: 4px;
    left: 4px;
    height: 16px;
    width: 16px;
    background-color: #ccc;
    border-radius: 10%;
}

/* On mouse-over, add a grey background color */
.dietaryCheckboxContainer:hover input ~ .checkbox {
    background-color: #aaa;
}

/* When the checbox button is checked, add a pink background */
.dietaryCheckboxContainer input:checked ~ .checkbox {
    background-color: #ff709c;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkbox:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.dietaryCheckboxContainer input:checked ~ .checkbox:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.dietaryCheckboxContainer .checkbox:after {
    top: 4px;
    left: 4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}

.deleteIngredientButton {
    background-color: transparent;
    padding: 5px 10px;
    color: grey;
}

.deleteIngredientButton:hover {
    background-color: transparent;
    color: #ff3d79;
    cursor: pointer;
}

.cancelButton {
    background-color: transparent;
    border-radius: 3px;
    border: 2px solid transparent;
    color: #ff709c;
    margin: 20px 10px;
}

.cancelButton:Hover {
    background-color: transparent;
    border: 2px solid #ff709c;
}

.saveButton {
    margin: 20px 10px;
    border: 2px solid #ff709c;
}

.saveButton:hover {
    border: 2px solid #ff3d79;
}