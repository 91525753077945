html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #f3f3f3;

    /*background-image: url('./img/BlackTable.jpeg');
    background-position: center;
    background-repeat: repeat;
    background-size: cover;*/
}

@media only screen and (max-width: 600px) {
    html, body {

    }
}

.subIngredientTitle{
    font-weight: 400;
}

.madeByText p{
    position: fixed;
    bottom: 0;
    right: 0;
    color: white;
    font-weight: 100;
    margin-bottom: 3px;
    margin-right: 5px;
    font-size: 1em;
}

p, h1, h2, h3, h4, h5, h6, li, ol, ul {
    font-family: "Helvetica Neue", Verdana, sans-serif;
    font-weight: 100;
}

p, h2, h3 {
    margin: 0;
}

p, li, ol, ul {
    font-size: 1.1em;
    font-weight: 300;
}

h1 {
    font-size: 4em;
    font-weight: 300;
}

h2 {
    font-size: 3em;
    font-weight: 300;
}

@media only screen and (max-width: 600px) {
    h2 {
        font-size: 2.5em;
    }
}

h3 {
    font-size: 2em;
}

button {
    background-color: #ff709c;
    border-radius: 3px;
    padding: 10px 25px;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.1em;
    font-weight: 300;
    font-family: "Helvetica Neue", Verdana, sans-serif;
}

.buttonAddIngredient {
    padding: 5px 12px;
}

button:hover {
    background-color: #ff3d79;
    cursor: pointer;
}

input {
    outline: 0;
    border-width: 0 0 1px;
    border-color: #1d1d1b;
    margin-left: 0;
    font-size: 1em;
    font-family: "Helvetica Neue", Verdana, sans-serif;
    font-weight: 200;
    background-color: transparent;

    margin-bottom: 20px;
}

input:hover {
    border-color: #ff709c;
}

input:focus {
    border-color: #ff709c;
}

.router {
    overflow: scroll;
    height: 93vh;
}

@media only screen and (max-width: 600px) {
    .router {
        height: 80vh;
    }
}


/* ------ HOVER EFFECT FOR LINKS (ON ALL RECIPES PAGE) ------ */
.link {
    background-color: transparent;
    padding: 5px 0;
}

.link:after {
    content: '';
    display: block;
    margin: auto;
    height: 1px;
    width: 0;
    background: transparent;
    -webkit-transition: width .5s ease, background-color .5s ease;
    transition: width .5s ease, background-color .5s ease;
}

.link:hover:after {
    width: 100%;
    background: black;
}

.linkContainer a {
    font-family: "Helvetica Neue", Verdana, sans-serif;
    font-weight: 100;
    font-size: 1.3em;
    color: black;
    text-decoration: none;
}

.link a:hover {
}


/* ------ FORM ------ */

.formContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 100%;
    width: 100%;
}

.formContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    padding: 30px;
}

/* ------ LOGO ------ */

.logoContainer {
    background-color: transparent;
    border-radius: 3px;
    background-image: url(/static/media/LogoWhiteLong.6875f40b.png);
    background-size: cover;
    background-position: center;
    height: 40px;
    pointer-events: auto;
}

.logoContainer:hover {
    cursor: pointer;
}

.logoContainer img{
    opacity: 0;
    -webkit-transition: opacity 0.3s ease-out;
    transition: opacity 0.3s ease-out;
}

/* ------ LOGIN/OUT ------ */
.loginOrOutContainer {
    pointer-events: auto; /* Enable clicking again, after parent disablet it */
}
.notLandingPage {
    height: 93vh;
    overflow-x: hidden;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
}

.landingPageBG {
    /*background-image: url('../img/MAPA.jpg');
    background-position: center;*/

    /*background-image: url('../img/MAPA2.jpg');
    background-position: center;*/

    background-image: url(/static/media/NorwayMountains.4a69e634.jpg);
    background-position: bottom;

    background-repeat: no-repeat;
    background-size: cover;
    background-color: transparent;
}

.landingPage {
    background-color: #ff709cFF;
    -webkit-transition: height 0.6s ease-out, background-color 0.3s ease-out;
    transition: height 0.6s ease-out, background-color 0.3s ease-out;

    display: flex;
    /*height: 100%;*/
    flex-direction: column;
    justify-content: center;
    padding: 20px 0;
}

.landingPageHeight {
    height: 100vh;
}

.notLandingPageHeight {
    height: 7vh;
    overflow: hidden;
}

@media only screen and (max-width: 600px) {
    .notLandingPageHeight {
        height: 25vh;
        padding: 0;
    }
}

.logoAndLoginContainer {
    position: absolute;
    top: 25px;
    pointer-events: none; /* To stop the div from disabling clicks on div underneath */
}

@media only screen and (max-width: 600px) {
    .logoAndLoginContainer {
        top: 0;
    }
}

.loginContainer {
    flex: 0 1 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    height: 100%;
}

.landingPageContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100%;

    flex: 1 1 auto;
    /* The above is shorthand for:
    flex-grow: 1,
    flex-shrink: 1,
    flex-basis: auto
    */
}

@media only screen and (max-width: 600px) {
    .landingPageContainer {
        margin-top: 80px;
    }
}

.center {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

.landingPageJumbo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    width: 100%;
    padding-bottom: 15px;
}

@media only screen and (max-width: 600px) {
    .landingPageJumbo {
        padding: 15px 0;
    }
}

.searchBarContainer {
    width: 100%;
    height: 30px;
    margin-bottom: 5px;
    border-bottom: 1px solid white;
}

.landingPageLinksContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    width: 100%;
}

/* ######################  RECIPE PAGE  ############ */

.recipePage {
    min-height: 90%;
    width: 100%;
    padding-bottom: 80px;

    overflow: hidden;
}

.recipePageHeader {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    background-color: darkgray;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    width: 100%;
    height: 45vh;
}

.imageAndTitleContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    min-height: 25vh;
}

.recipePageJumboContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;

    height: inherit;
    background-color: #0b86bf00; /* ADJUST OPACITY HERE */
}

.recipePageJumbo {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    height: 100%;
}

.recipePageContainer {

    width: 100%;
    /*min-height: 58vh;*/
    min-height: 90%;
    max-height: 100%; /* - if I want the recipe to minimum fit into vh on scroll down */
    background-color: #f3f3f3;
    margin-bottom: 20px;
    padding: 12px;
}

.instructionsContainer {
    background-color: #f3f3f3;
}

/* ######################  ALL RECIPES PAGE  ############ */

.allRecipesPage {
    min-height: 90%;
    width: 100%;
    padding-bottom: 80px;

    overflow: hidden;
}

.allRecipesPageContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #f3f3f3;

    padding: 20px;
}

/* ######################  LOGIN PAGE ##################### */

.loginPage {
    min-height: 90%;
    width: 100%;
    padding-bottom: 80px;

    overflow: hidden;
}

.loginPageContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #f3f3f3;

    padding: 20px;
}

/* ######################  EDIT RECIPE PAGE ##################### */

.adminPageContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    width: 100%;
}

.editRecipePage {
    min-height: 90%;
    padding-bottom: 80px;

    overflow-y: scroll;
    overflow-x: hidden;
}

.editRecipePageContainer {
    /*min-height: 58vh;*/
    min-height: 90%;
    max-height: 100%; /* - if I want the recipe to minimum fit into vh on scroll down */
    background-color: #f3f3f3;
    margin-bottom: 20px;
    padding: 12px;
    padding-top: 40px;
}

.ingredientsInputContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    width: 100%;
}

.richEditor {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    width: 100%;
}

.inputFieldsContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.formLabelContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-start;
}

.editPageInput {
    width: 100%;
}

.editPageInputNumber {
    width: 40px;
}

.radioButtonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.radioButtonContainer {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    margin-right: 20px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default radio button */
.radioButtonContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom radio button */
.checkmark {
    position: absolute;
    top: 4px;
    left: 4px;
    height: 16px;
    width: 16px;
    background-color: #ccc;
    border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radioButtonContainer:hover input ~ .checkmark {
    background-color: #aaa;
}

/* When the radio button is checked, add a blue background */
.radioButtonContainer input:checked ~ .checkmark {
    background-color: #ff709c;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.radioButtonContainer input:checked ~ .checkmark:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.radioButtonContainer .checkmark:after {
    top: 4px;
    left: 4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}

.selectMealForm {

}

.imagePreviewContainer {
    width: 100%;
    height: 45vh;

    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    margin-bottom: 20px;
}

.dietaryCheckboxesContainer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.dietaryCheckboxContainer {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-bottom: 12px;
    margin-right: 20px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/* Hide the browser's default checbox button */
.dietaryCheckboxContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* Create a custom checbox button */
.checkbox {
    position: absolute;
    top: 4px;
    left: 4px;
    height: 16px;
    width: 16px;
    background-color: #ccc;
    border-radius: 10%;
}

/* On mouse-over, add a grey background color */
.dietaryCheckboxContainer:hover input ~ .checkbox {
    background-color: #aaa;
}

/* When the checbox button is checked, add a pink background */
.dietaryCheckboxContainer input:checked ~ .checkbox {
    background-color: #ff709c;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.checkbox:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the indicator (dot/circle) when checked */
.dietaryCheckboxContainer input:checked ~ .checkbox:after {
    display: block;
}

/* Style the indicator (dot/circle) */
.dietaryCheckboxContainer .checkbox:after {
    top: 4px;
    left: 4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
}

.deleteIngredientButton {
    background-color: transparent;
    padding: 5px 10px;
    color: grey;
}

.deleteIngredientButton:hover {
    background-color: transparent;
    color: #ff3d79;
    cursor: pointer;
}

.cancelButton {
    background-color: transparent;
    border-radius: 3px;
    border: 2px solid transparent;
    color: #ff709c;
    margin: 20px 10px;
}

.cancelButton:Hover {
    background-color: transparent;
    border: 2px solid #ff709c;
}

.saveButton {
    margin: 20px 10px;
    border: 2px solid #ff709c;
}

.saveButton:hover {
    border: 2px solid #ff3d79;
}
.react-autosuggest__container {
    position: relative;
    width: 100%;
    height: 100%;
}

.react-autosuggest__input {
    width: 100%;
    padding: 5px 0;
    background-color: transparent;
    border-bottom: none;
    color: white;
}

.react-autosuggest__input--focused {
    outline: none;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 31px;
    width: 100%;
    background-color: #fff;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 1;
    right: 0;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    display: block;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}

.react-autosuggest__input::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 0.5; /* Firefox */
}

.react-autosuggest__input::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 0.5; /* Firefox */
}

.react-autosuggest__input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: white;
    opacity: 0.5; /* Firefox */
}
.button {
     background-color: transparent;
     padding: 5px 0;
}

.button:after {
    content: '';
    display: block;
    margin: auto;
    height: 1px;
    width: 0;
    background: transparent;
    -webkit-transition: width .5s ease, background-color .5s ease;
    transition: width .5s ease, background-color .5s ease;
}

.button:hover:after {
    width: 100%;
    background: white;
}

.button a {
    font-family: "Helvetica Neue", Verdana, sans-serif;
    font-weight: 100;
    font-size: 1.3em;
    color: white;
    text-decoration: none;
}

.button a:hover {
}

.button.links a {
    font-size: 1em;
    text-decoration: none;
}






.buttonEdit {
    font-family: "Helvetica Neue", Verdana, sans-serif;
    font-weight: 300;
    font-size: 1.3em;
    background-color: forestgreen;
    padding: 5px 10px;
    margin: 10px;
    border-radius: 5px;
    color: white;
    text-decoration: none;
}

.buttonEdit:hover {
    background-color: darkgreen;
    cursor: pointer;
}

.buttonDelete {
    font-family: "Helvetica Neue", Verdana, sans-serif;
    font-weight: 300;
    font-size: 1.3em;
    background-color: red;
    padding: 5px 10px;
    margin: 10px;
    border-radius: 5px;
    color: white;
    text-decoration: none;
}

.buttonDelete:hover {
    background-color: darkred;
    cursor: pointer;
}

.signInAndOutButton {
    font-family: "Helvetica Neue", Verdana, sans-serif;
    font-weight: 300;
    font-size: 1.3em;
    padding: 5px 10px;
    margin: 10px;
    color: white;
    text-decoration: none;
}

.signInAndOutButton:hover {
    cursor: pointer;
}
.RichEditor-root {
    background: #fff;
    border: 1px solid #ddd;
    font-family: "Helvetica Neue", Verdana, sans-serif;
    font-size: 14px;
    padding: 15px;
}

.RichEditor-editor {
    border-top: 1px solid #ddd;
    cursor: text;
    font-size: 16px;
    margin-top: 10px;
}

.RichEditor-editor .public-DraftEditorPlaceholder-root,
.RichEditor-editor .public-DraftEditor-content {
    margin: 0 -15px -15px;
    padding: 15px;
}

.RichEditor-editor .public-DraftEditor-content {
    min-height: 100px;
}

.RichEditor-hidePlaceholder .public-DraftEditorPlaceholder-root {
    display: none;
}

.RichEditor-editor .RichEditor-blockquote {
    border-left: 5px solid #eee;
    color: #666;
    font-family: 'Hoefler Text', 'Georgia', serif;
    font-style: italic;
    margin: 16px 0;
    padding: 10px 20px;
}

.RichEditor-editor .public-DraftStyleDefault-pre {
    background-color: rgba(0, 0, 0, 0.05);
    font-family: 'Inconsolata', 'Menlo', 'Consolas', monospace;
    font-size: 16px;
    padding: 20px;
}

.RichEditor-controls {
    font-family: 'Helvetica', sans-serif;
    font-size: 14px;
    margin-bottom: 5px;
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
}

.RichEditor-styleButton {
    color: #999;
    cursor: pointer;
    margin-right: 16px;
    padding: 2px 0;
    display: inline-block;
}

.RichEditor-activeButton {
    color: #ff709c;
}
