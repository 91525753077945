html, body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-color: #f3f3f3;

    /*background-image: url('./img/BlackTable.jpeg');
    background-position: center;
    background-repeat: repeat;
    background-size: cover;*/
}

@media only screen and (max-width: 600px) {
    html, body {

    }
}

.subIngredientTitle{
    font-weight: 400;
}

.madeByText p{
    position: fixed;
    bottom: 0;
    right: 0;
    color: white;
    font-weight: 100;
    margin-bottom: 3px;
    margin-right: 5px;
    font-size: 1em;
}

p, h1, h2, h3, h4, h5, h6, li, ol, ul {
    font-family: "Helvetica Neue", Verdana, sans-serif;
    font-weight: 100;
}

p, h2, h3 {
    margin: 0;
}

p, li, ol, ul {
    font-size: 1.1em;
    font-weight: 300;
}

h1 {
    font-size: 4em;
    font-weight: 300;
}

h2 {
    font-size: 3em;
    font-weight: 300;
}

@media only screen and (max-width: 600px) {
    h2 {
        font-size: 2.5em;
    }
}

h3 {
    font-size: 2em;
}

button {
    background-color: #ff709c;
    border-radius: 3px;
    padding: 10px 25px;
    border: none;
    color: white;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 1.1em;
    font-weight: 300;
    font-family: "Helvetica Neue", Verdana, sans-serif;
}

.buttonAddIngredient {
    padding: 5px 12px;
}

button:hover {
    background-color: #ff3d79;
    cursor: pointer;
}

input {
    outline: 0;
    border-width: 0 0 1px;
    border-color: #1d1d1b;
    margin-left: 0;
    font-size: 1em;
    font-family: "Helvetica Neue", Verdana, sans-serif;
    font-weight: 200;
    background-color: transparent;

    margin-bottom: 20px;
}

input:hover {
    border-color: #ff709c;
}

input:focus {
    border-color: #ff709c;
}

.router {
    overflow: scroll;
    height: 93vh;
}

@media only screen and (max-width: 600px) {
    .router {
        height: 80vh;
    }
}


/* ------ HOVER EFFECT FOR LINKS (ON ALL RECIPES PAGE) ------ */
.link {
    background-color: transparent;
    padding: 5px 0;
}

.link:after {
    content: '';
    display: block;
    margin: auto;
    height: 1px;
    width: 0;
    background: transparent;
    transition: width .5s ease, background-color .5s ease;
}

.link:hover:after {
    width: 100%;
    background: black;
}

.linkContainer a {
    font-family: "Helvetica Neue", Verdana, sans-serif;
    font-weight: 100;
    font-size: 1.3em;
    color: black;
    text-decoration: none;
}

.link a:hover {
}


/* ------ FORM ------ */

.formContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 100%;
    width: 100%;
}

.formContent {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    padding: 30px;
}

/* ------ LOGO ------ */

.logoContainer {
    background-color: transparent;
    border-radius: 3px;
    background-image: url('./img/LogoWhiteLong.png');
    background-size: cover;
    background-position: center;
    height: 40px;
    pointer-events: auto;
}

.logoContainer:hover {
    cursor: pointer;
}

.logoContainer img{
    opacity: 0;
    transition: opacity 0.3s ease-out;
}

/* ------ LOGIN/OUT ------ */
.loginOrOutContainer {
    pointer-events: auto; /* Enable clicking again, after parent disablet it */
}