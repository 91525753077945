.button {
     background-color: transparent;
     padding: 5px 0;
}

.button:after {
    content: '';
    display: block;
    margin: auto;
    height: 1px;
    width: 0;
    background: transparent;
    transition: width .5s ease, background-color .5s ease;
}

.button:hover:after {
    width: 100%;
    background: white;
}

.button a {
    font-family: "Helvetica Neue", Verdana, sans-serif;
    font-weight: 100;
    font-size: 1.3em;
    color: white;
    text-decoration: none;
}

.button a:hover {
}

.button.links a {
    font-size: 1em;
    text-decoration: none;
}






.buttonEdit {
    font-family: "Helvetica Neue", Verdana, sans-serif;
    font-weight: 300;
    font-size: 1.3em;
    background-color: forestgreen;
    padding: 5px 10px;
    margin: 10px;
    border-radius: 5px;
    color: white;
    text-decoration: none;
}

.buttonEdit:hover {
    background-color: darkgreen;
    cursor: pointer;
}

.buttonDelete {
    font-family: "Helvetica Neue", Verdana, sans-serif;
    font-weight: 300;
    font-size: 1.3em;
    background-color: red;
    padding: 5px 10px;
    margin: 10px;
    border-radius: 5px;
    color: white;
    text-decoration: none;
}

.buttonDelete:hover {
    background-color: darkred;
    cursor: pointer;
}

.signInAndOutButton {
    font-family: "Helvetica Neue", Verdana, sans-serif;
    font-weight: 300;
    font-size: 1.3em;
    padding: 5px 10px;
    margin: 10px;
    color: white;
    text-decoration: none;
}

.signInAndOutButton:hover {
    cursor: pointer;
}